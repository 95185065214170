import React from "react";

const EducationSection = () => {
  return (
    <>
      <h3>EDUCATION</h3>
      <article>
        <div className="titleWrapper">
          <h4 className="sectionItemTitle">
            BS Electrical Engineering / Computer Engineering
          </h4>
          <span className="sectionItemSubtitle">University of Connecticut</span>
          <span className="emphasizedSubtitle">09/2008 - 05/2011</span>
        </div>
      </article>
      <article>
        <div className="titleWrapper">
          <h4 className="sectionItemTitle">
            AS Computer Science / Engineering Science
          </h4>
          <span className="sectionItemSubtitle">
            Manchester Community College
          </span>
          <span className="emphasizedSubtitle">01/2005 - 05/2008</span>
        </div>
      </article>
    </>
  );
};

export default EducationSection;
